﻿.search {
    &-container {
        &--startpage {
            margin-right: -15px;
            margin-left: -15px;
            padding: 32px 16px;
            color: white;
            background-color: $c-charcoal;

            @include tablet {
                margin-right: -60px;
                margin-left: -60px;
            }

            @include phone {
                margin-right: -24px;
                margin-left: -24px;
            }

            .search-container {
                max-width: 800px;
                margin-right: auto;
                margin-left: auto;

                atomic-search-interface {
                    margin-bottom: 0;
                }
            }

            atomic-search-box::part(submit-button),
            atomic-search-box::part(submit-button):hover {
                color: #000;
                background-color: #7aea97;
            }
        }
    }

    &-heading {
        &-container {
            margin-top: 3rem;
        }
    }

    &-results {
        &-summary {
            font-size: 1rem;
            color: $c-ash;

            @include phone {
                font-size: 0.777778rem;
            }

            &--pre {
                font-family: $font-family-book;
            }

            &--query {
                display: block;
                margin-top: 1rem;
                font-size: 3rem;
                color: $c-black;

                @include phone {
                    margin-top: 0.5rem;
                    font-size: 1.77778rem;
                }
            }

            &--none {
                display: block;
                margin-top: 3rem;
                padding: 2rem 20px;
                text-align: center;
                background-color: $c-concrete;
            }
        }
    }

    &-result {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 6px solid $c-concrete;

        &:first-child {
            border-top: 6px solid $c-concrete;
        }

        &-title {
            margin-bottom: 0;
            font-family: $font-family-medium;
            font-size: 1.125rem;

            + .search-result-abstract {
                margin-top: 0.5rem;
            }

            @include phone {
                font-size: 0.88889rem;
            }
        }

        &-abstract {
            max-width: 700px;
            font-size: 0.875rem;

            @include phone {
                font-size: 0.777778rem;
            }
        }

        a {
            display: block;
            text-decoration: none;
            color: $c-black;

            &:hover,
            &:focus {
                .search-result-title {
                    text-decoration: underline;
                }
            }
        }
    }

    &-facet {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;

        @include phone {
            margin-right: 5px;
            font-size: 0.75rem;
        }

        &s-container {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        a {
            display: inline-block;
            text-decoration: none;
            letter-spacing: 0.03rem;
            color: $c-ash;

            &:hover,
            &:visited,
            &:active {
                text-decoration: none;
            }

            @include phone {
                padding: 7px 10px;
                border: 1px solid $c-chatelle;
            }
        }

        &.active a {
            color: $c-black;
            border-bottom: 2px solid $c-corporate;

            @include phone {
                color: $c-white;
                background-color: $c-corporate;
                border-color: $c-corporate;
            }
        }

        &-label {
            font-family: $font-family-medium;
        }
    }

    &-more {
        margin-top: 1rem;
    }

    &-spinner {
        &-wrapper {
            position: relative;
            width: 96px;
            height: 96px;
            z-index: 100;
            margin: 3rem auto 0;
        }
    }

    &-lists {
        display: flex;
        gap: 150px;
        width: auto;
        margin-top: 2rem;

        @include tablet {
            gap: 30px;
        }

        .search-list {
            p  {
                margin-bottom: 1.25rem;
                font-family: $font-family-medium;
            }

            ul {
                margin: 0;
                padding-left: 0;
                list-style-type: none;

                li {
                    margin-bottom: .75rem;
                }
            }
        }
    }
}